import { useEffect, useState, Fragment, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { GetOneJob, AddMiscToAJob, UpdateJobMisc, RemoveJobMisc, GetAllCannedFees } from "../../actions/jobs";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { formatCurrency } from "../../components/tools";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Modal, Switch, Tag } from "antd";
import { Wrench } from "iconoir-react";
import { Helmet } from "react-helmet-async";

const JobMisc = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [dummyLoading, setDummyLoading] = useState(false);
  let [job, setJob] = useState({});
  // eslint-disable-next-line
  let [location, setLocation] = useState({});
  let [addMiscModal, setAddMiscModal] = useState(false);
  let [miscData, setMiscData] = useState({
    miscType: "",
    partNo: "",
    description: "",
    quantity: 0,
    chargeOutPrice: 0,
    miscId: "",
    chargeToCustomer: false,
  });
  let [editMiscModal, setEditMiscModal] = useState(false);
  let [presetFees, setPresetFees] = useState(false);
  let [fees, setFees] = useState([]);

  const { jobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneJob(jobId)
        .then((res) => {
          setJob(res.data.job);
          setLocation(res.data.location);
          GetAllCannedFees()
            .then((res) => {
              setFees(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data.message ? err.response.data.message : "Error loading canned fees");
              setLoading(false);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
          setTimeout(() => {
            navigate("/jobs");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
    // eslint-disable-next-line
  }, []);

  const tabs = [
    { name: "Info", href: "#", current: false },
    { name: "Parts", href: "parts", current: false },
    { name: "Labor", href: "labor", current: false },
    { name: "Parking", href: "parking", current: false },
    { name: "Loadbank", href: "loadbank", current: false },
    { name: "Freight", href: "freight", current: false },
    { name: "Misc", href: "misc", current: true },
    { name: "POs", href: "purchaseOrders", current: false },
    {
      name: "ITs",
      href: "inventoryTransmittals",
      current: false,
    },
    { name: "Equip.", href: "equipment", current: false },
    { name: "Tests", href: "tests", current: false },
    { name: "Warranty", href: "warranty", current: false },
    { name: "Payments", href: "payments", current: false },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const renderMiscLine = (line) => {
    return (
      <div className="grid items-center w-full grid-cols-3 gap-4 py-3 border-b border-gray-300 md:grid-cols-7" key={uuidv4()}>
        <p className="capitalize">{line.miscType}</p>
        <p className="truncate">{line.description}</p>
        <p className="hidden capitalize md:inline-flex">{line.miscType === "part" ? line.partNo : "N/A"}</p>
        <p className="hidden capitalize md:inline-flex">{line.miscType === "part" ? renderStatus(line.partStatus) : "N/A"}</p>
        <p>{line.quantity}</p>
        {job.warranty && line.chargeToCustomer ? (
          <div className="flex-col items-start justify-center hidden gap-1 md:flex">
            <p className="text-center">{formatCurrency(line.chargeOutPrice)}</p>
            <Tag color="blue" className="py-0.5 font-medium" style={{ fontSize: "0.6rem", lineHeight: "0.75rem" }}>
              Charged to Customer
            </Tag>
          </div>
        ) : (
          <p className="hidden md:inline-flex">{formatCurrency(line.chargeOutPrice)}</p>
        )}
        {job.jobStatus === "open" && (
          <div className="flex flex-row items-center justify-center gap-2">
            <SecondaryButton label="Remove" callback={() => submitMiscRemove(line.miscId)} />
            <SecondaryButton label="Edit" callback={() => openMiscEdit(line.miscId)} />
          </div>
        )}
      </div>
    );
  };

  const renderStatus = (status) => {
    if (status === "entered") {
      return "Entered";
    } else if (status === "inventory") {
      return "Inventory";
    } else if (status === "onOrder") {
      return "On Order";
    } else if (status === "returnedToInventory") {
      return "Returned to Inventory";
    } else if (status === "returnedToVendor") {
      return "Returned to Vendor";
    } else if (status === "partiallyAvailable") {
      return "Partially Available";
    } else if (status === "unavailable") {
      return "Unavailable";
    } else if (status === "backOrdered") {
      return "Back Ordered";
    } else {
      return "Unknown";
    }
  };

  const renderAddMiscModal = () => {
    return (
      <Transition.Root show={addMiscModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeAddMiscModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add Misc Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 py-2 mt-2">
                        <div key="parkingType" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="parkingType" className="pb-1 text-xs text-gray-600 uppercase">
                            Fee Type
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="miscType"
                            defaultValue=""
                            onChange={changeMiscData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="service" value="service">
                              Service
                            </option>
                            <option key="part" value="part">
                              Part
                            </option>
                          </select>
                        </div>
                        <div key="partNo" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="partNo" className="pb-1 text-xs text-gray-600 uppercase">
                            Part No.
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="partNo"
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="description" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                            Description
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="description"
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="quantity" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="quantity" className="pb-1 text-xs text-gray-600 uppercase">
                            Quantity
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="quantity"
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="chargeOutPrice" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="chargeOutPrice" className="pb-1 text-xs text-gray-600 uppercase">
                            Charge Out Price
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="chargeOutPrice"
                            onChange={changeMiscData}
                          />
                        </div>
                        {job.warranty && (
                          <div className="flex items-center justify-between w-full px-1 py-4">
                            <label className="block text-sm font-medium leading-6 text-gray-900">Charge to Customer</label>
                            <Switch
                              defaultChecked={miscData.chargeToCustomer}
                              onChange={(v) =>
                                setMiscData({
                                  ...miscData,
                                  chargeToCustomer: v,
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeAddMiscModal()} />
                    <PrimaryButton label="Add Misc Fee" callback={() => addNewMisc()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderMiscEditModal = () => {
    return (
      <Transition.Root show={editMiscModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeMiscEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-2xl sm:px-6">
                  <div>
                    <div>
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit Misc Fee
                      </Dialog.Title>
                      <div className="flex flex-col items-center justify-start gap-4 py-2 mt-2">
                        <div key="parkingType" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="parkingType" className="pb-1 text-xs text-gray-600 uppercase">
                            Fee Type
                          </label>
                          <select
                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            id="miscType"
                            defaultValue={miscData.miscType}
                            onChange={changeMiscData}
                          >
                            <option hidden disabled value="">
                              Select One
                            </option>
                            <option key="service" value="service">
                              Service
                            </option>
                            <option key="part" value="part">
                              Part
                            </option>
                          </select>
                        </div>
                        <div key="partNo" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="partNo" className="pb-1 text-xs text-gray-600 uppercase">
                            Part No.
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="partNo"
                            defaultValue={miscData.partNo}
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="description" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                            Description
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="description"
                            defaultValue={miscData.description}
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="quantity" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="quantity" className="pb-1 text-xs text-gray-600 uppercase">
                            Quantity
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="text"
                            id="quantity"
                            defaultValue={miscData.quantity}
                            onChange={changeMiscData}
                          />
                        </div>
                        <div key="chargeOutPrice" className="flex flex-col items-start justify-start w-full">
                          <label htmlFor="chargeOutPrice" className="pb-1 text-xs text-gray-600 uppercase">
                            Charge Out Price
                          </label>
                          <input
                            className="w-full px-3 py-1 text-gray-900 border border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6"
                            type="number"
                            id="chargeOutPrice"
                            defaultValue={miscData.chargeOutPrice}
                            onChange={changeMiscData}
                          />
                        </div>
                        {job.warranty && (
                          <div className="flex items-center justify-between w-full px-1 py-4">
                            <label className="block text-sm font-medium leading-6 text-gray-900">Charge to Customer</label>
                            <Switch
                              defaultChecked={miscData.chargeToCustomer}
                              onChange={(v) =>
                                setMiscData({
                                  ...miscData,
                                  chargeToCustomer: v,
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-2 mt-5">
                    <SecondaryButton label="Cancel" callback={() => closeMiscEdit()} />
                    <PrimaryButton label="Save" callback={() => submitMiscEdit()} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeAddMiscModal = () => {
    setAddMiscModal(false);
    setMiscData({
      miscType: "",
      partNo: "",
      description: "",
      quantity: 0,
      chargeOutPrice: 0,
      miscId: "",
    });
  };

  const addNewMisc = () => {
    let failed = false;

    if (miscData.miscType === "") {
      toast.error("Please select a fee type");
      failed = true;
    } else if (miscData.description === "") {
      toast.error("Please enter a description");
      failed = true;
    } else if (miscData.quantity === 0) {
      toast.error("Please enter a quantity");
      failed = true;
    }

    if (!job.warranty) {
      miscData.chargeToCustomer = false;
    }

    if (!failed) {
      setLoading(true);
      AddMiscToAJob(jobId, miscData)
        .then((res) => {
          toast.success("Misc fee added successfully");
          closeAddMiscModal();
          GetOneJob(jobId)
            .then((res) => {
              setJob(res.data.job);
              setLocation(res.data.location);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
              setTimeout(() => {
                navigate("/jobs");
              }, 3000);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error adding misc fee to this job");
          setLoading(false);
        });
    }
  };

  const changeMiscData = (e) => {
    setDummyLoading(true);
    let { id, value } = e.target;
    let tmp = miscData;
    if (id === "quantity" || id === "chargeOutPrice") {
      value = parseFloat(value);
    }
    tmp[id] = value;
    setMiscData(tmp);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const openMiscEdit = (id) => {
    let frt = _.find(job.misc, { miscId: id });
    if (frt) {
      setMiscData(frt);
      setTimeout(() => setEditMiscModal(true), 700);
    } else {
      toast.error("Something went wrong, please try again");
    }
  };

  const closeMiscEdit = () => {
    setEditMiscModal(false);
    setMiscData({
      miscType: "",
      partNo: "",
      description: "",
      quantity: 0,
      chargeOutPrice: 0,
      miscId: "",
    });
  };

  const submitMiscEdit = () => {
    let failed = false;

    if (miscData.miscType === "") {
      toast.error("Please select a fee type");
      failed = true;
    } else if (miscData.description === "") {
      toast.error("Please enter a description");
      failed = true;
    } else if (miscData.quantity === 0) {
      toast.error("Please enter a quantity");
      failed = true;
    } else if (miscData.chargeOutPrice === 0) {
      toast.error("Please enter a charge out price");
      failed = true;
    } else if (miscData.miscType === "part" && miscData.partNo === "") {
      toast.error("Please enter a part number");
      failed = true;
    }

    if (!job.warranty) {
      miscData.chargeToCustomer = false;
    }

    if (!failed) {
      setLoading(true);
      UpdateJobMisc(jobId, miscData.miscId, miscData)
        .then((res) => {
          toast.success("Misc fee updated successfully");
          closeMiscEdit();
          GetOneJob(jobId)
            .then((res) => {
              setJob(res.data.job);
              setLocation(res.data.location);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
              setTimeout(() => {
                navigate("/jobs");
              }, 3000);
            });
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error updating misc fee");
          setLoading(false);
        });
    }
  };

  const submitMiscRemove = (miscId) => {
    setLoading(true);
    let misc = _.find(job.misc, { miscId: miscId });
    RemoveJobMisc(jobId, miscId, misc.partNo)
      .then((res) => {
        toast.success("Misc fee removed successfully");
        GetOneJob(jobId)
          .then((res) => {
            setJob(res.data.job);
            setLocation(res.data.location);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
            setTimeout(() => {
              navigate("/jobs");
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error removing misc fee");
        setLoading(false);
      });
  };

  const renderFeeLineItem = (fee) => {
    return (
      <div className="flex flex-col w-full px-4 py-3 bg-white border border-gray-100 shadow-sm rounded-2xl">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="p-2.5 border border-blue-100 bg-blue-50 rounded-xl">
              <Wrench className="w-auto h-6 text-blue-400" />
            </div>
            <div className="flex flex-col ml-3">
              <div className="text-base font-semibold leading-none">{fee.name}</div>
              <p className="mt-1 text-sm font-medium leading-none text-gray-500">{formatCurrency(fee.price)}</p>
            </div>
          </div>
          <button
            className="px-5 py-2 ml-4 text-xs font-semibold tracking-wider text-white uppercase transition-all duration-300 bg-blue-500 border-2 border-blue-500 rounded-lg shadow-sm hover:bg-blue-600 flex-no-shrink hover:shadow-lg"
            onClick={() => addFee(fee)}
          >
            Add Fee
          </button>
        </div>
      </div>
    );
  };

  const addFee = (fee) => {
    let payload = {
      miscType: "service",
      partNo: "SERVICE FEE",
      description: fee.name,
      quantity: 1,
      chargeOutPrice: fee.price,
    };
    setLoading(true);
    AddMiscToAJob(jobId, payload)
      .then((res) => {
        toast.success("Fee added successfully");
        setPresetFees(false);
        GetOneJob(jobId)
          .then((res) => {
            setJob(res.data.job);
            setLocation(res.data.location);
            setTimeout(() => setLoading(false), 700);
          })
          .catch((err) => {
            toast.error(err.response.data.message ? err.response.data.message : "Error loading job information");
            setTimeout(() => {
              navigate(`/jobs/${jobId}/misc`, { replace: true });
            }, 3000);
          });
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error adding misc fee to this job");
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Misc - {job.jobNo} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-blue-700 focus:outline-none focus:ring-blue-700 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/jobs/${jobId}/${v.target.value}`)}
              >
                {tabs.map((tab) => (
                  <option value={tab.href}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <p
                      value={tab.name}
                      key={uuidv4()}
                      onClick={() => navigate(`/jobs/${jobId}/${tab.href}`)}
                      className={classNames(
                        tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </p>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full px-1 mt-1">
            <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
              <p className="text-xl font-bold uppercase">Misc. Job Charges</p>
              <div className="flex items-center justify-end gap-1.5">
                {job.jobStatus === "open" && <SecondaryButton label="Preset Misc. Fees" callback={() => setPresetFees(true)} />}
                {job.jobStatus === "open" && <PrimaryButton label="Add a Misc. Charge" callback={() => setAddMiscModal(true)} />}
              </div>
            </div>
            {job.misc.length === 0 ? (
              <div className="flex items-center justify-center w-full py-5">
                <p className="text-sm font-semibold uppercase text-gray-500/80">No misc fees were added to this job</p>
              </div>
            ) : (
              <>
                <div className="grid w-full grid-cols-3 gap-4 py-3 border-b border-gray-300 md:grid-cols-7">
                  <p className="text-xs font-semibold uppercase">Charge Type</p>
                  <p className="text-xs font-semibold uppercase">Description</p>
                  <p className="hidden text-xs font-semibold uppercase md:inline-flex">Part No</p>
                  <p className="hidden text-xs font-semibold uppercase md:inline-flex">Part Status</p>
                  <p className="text-xs font-semibold uppercase">Quantity</p>
                  <p className="hidden text-xs font-semibold uppercase md:inline-flex">Charge Out</p>
                  <div className="flex-row items-center justify-center hidden md:flex"></div>
                </div>
                {job.misc.map((line, index) => renderMiscLine(line, index))}
              </>
            )}
          </div>
          {renderAddMiscModal()}
          {renderMiscEditModal()}
          <Modal open={presetFees} onCancel={() => setPresetFees(false)} footer={null} centered destroyOnClose title="Preset Misc. Service Fees" width={900}>
            <div className="grid items-center justify-center w-full grid-cols-2 gap-2 my-2 max-h-[800px] overflow-y-auto">
              {fees.map((fee) => renderFeeLineItem(fee))}
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default JobMisc;
