import { Routes, Route, Navigate } from "react-router-dom";
import NavigationWrapper from "../components/NavigationWrapper";
import Dashboard from "../pages/dashboard";
import NewCustomer from "../pages/customers/newCustomer";
import CustomerID from "../pages/customers/customerId";
import CustomerDefaults from "../pages/customers/defaults";
import CustomerContact from "../pages/customers/contact";
import CustomerAR from "../pages/customers/ar";
import CustomerEquipment from "../pages/customers/equipment";
import CustomerQuotes from "../pages/customers/quotes";
import CustomerJobs from "../pages/customers/jobs";
import CustomerInvoices from "../pages/customers/invoices";
import NewQuote from "../pages/quotes/newQuote";
import OpenQuote from "../pages/quotes/quoteId";
import QuoteReview from "../pages/quotes/quoteReview";
import NewVendor from "../pages/vendors/newVendor";
import JobFromQuote from "../pages/quotes/jobFromQuote";
import Timesheets from "../pages/settings/timesheets";
import PartID from "../pages/parts/partId";
import OpenJob from "../pages/jobs/jobId";
import JobParts from "../pages/jobs/parts";
import JobLabor from "../pages/jobs/labor";
import JobParking from "../pages/jobs/parking";
import JobLoadbankCharges from "../pages/jobs/loadbank";
import JobFreight from "../pages/jobs/freight";
import JobMisc from "../pages/jobs/misc";
import OpenPO from "../pages/purchaseOrders/poId";
import OpenAP from "../pages/ap/apId";
import NewAP from "../pages/ap/newAp";
import OpenVendor from "../pages/vendors/vendorId";
import OpenInvoice from "../pages/invoices/invoiceId";
import CollectInvoicePayment from "../pages/invoices/payment";
import OpenEquipment from "../pages/equipment/equipmentId";
import EquipmentDetails from "../pages/equipment/details";
import EquipmentEngine from "../pages/equipment/engine";
import EquipmentATS from "../pages/equipment/ats";
import EquipmentContact from "../pages/equipment/contact";
import EquipmentNotes from "../pages/equipment/notes";
import EquipmentServiceSchedule from "../pages/equipment/serviceSchedule";
import EquipmentHistory from "../pages/equipment/history";
import EquipmentPhotos from "../pages/equipment/photos";
import NewJob from "../pages/jobs/newJob";
import JobPurchaseOrders from "../pages/jobs/purchaseOrders";
import CreateANewPO from "../pages/purchaseOrders/newPurchaseOrder";
import JobInventoryTransmittals from "../pages/jobs/inventoryTransmittals";
import JobEquipment from "../pages/jobs/jobEquipment";
import JobTests from "../pages/jobs/tests";
import AnnualChecklist from "../pages/tests/annualChecklist";
import OpenAnnualMaintenance from "../pages/tests/view/annualChecklist";
import SemiAnnualChecklist from "../pages/tests/semiAnnualChecklist";
import OpenSemiAnnualMaintenance from "../pages/tests/view/semiAnnualChecklist";
import QuarterlyChecklist from "../pages/tests/quarterlyChecklist";
import OpenQuarterlyMaintenance from "../pages/tests/view/quarterlyChecklist";
import MonthlyChecklist from "../pages/tests/monthlyChecklist";
import OpenMonthlyMaintenance from "../pages/tests/view/monthlyChecklist";
import LoadbankTest from "../pages/tests/loadbankTest";
import OpenLoadbankTestLog from "../pages/tests/view/loadbankTestLog";
import KMAnnualTankTrailerInspection from "../pages/tests/kinderMorganAnnualTankTrailer";
import OpenKMTankTrailerInspection from "../pages/tests/view/kmTankTrailerInspection";
import LevelOneServiceChecklist from "../pages/tests/levelOneServiceChecklist";
import OpenLevelOneService from "../pages/tests/view/levelOneService";
import CitgoInspection from "../pages/tests/citgoInspection";
import OpenCitgoInspection from "../pages/tests/view/citgoInspection";
import LeakageTest from "../pages/tests/leakageTest";
import OpenLeakageTest from "../pages/tests/view/leakageTest";
import VisualInspection from "../pages/tests/visualInspection";
import OpenVisualInspection from "../pages/tests/view/visualInspection";
import WetTestCertification from "../pages/tests/wetTestCertification";
import OpenWetTestCertification from "../pages/tests/view/wetTestCertification";
import ExternalVisualInspection from "../pages/tests/externalVisualInspection";
import OpenExternalVisualInspection from "../pages/tests/view/externalVisualInspection";
import Settings from "../pages/settings";
import Logout from "../pages/auth/logout";
import Reports from "../pages/reports";
import APOpenInvoices from "../pages/reports/financial/apOpenInvoices";
import EquipmentLog from "../pages/reports/customer/equipmentLog";
import InventoryCount from "../pages/reports/operational/inventoryCount";
import OpenJobList from "../pages/reports/operational/openJobList";
import UpcomingServiceReport from "../pages/reports/customer/upcomingServiceReport";
import Documentation from "../pages/documentation";
import NewEquipment from "../pages/equipment/newEquipment";
import NewEquipmentATS from "../pages/equipment/newATS";
import OpenEquipmentATS from "../pages/equipment/atsId";
import NewPart from "../pages/parts/newPart";
import Changelog from "../pages/changelog";
import EditAnnualChecklist from "../pages/tests/edit/annualChecklist";
import EditSemiAnnualChecklist from "../pages/tests/edit/semiAnnualChecklist";
import EditQuarterlyChecklist from "../pages/tests/edit/quarterlyChecklist";
import EditMonthlyChecklist from "../pages/tests/edit/monthlyChecklist";
import EditLevelOneChecklist from "../pages/tests/edit/levelOneServiceChecklist";
import EditLoadbankTest from "../pages/tests/edit/loadbankTest";
import EditLeakageTest from "../pages/tests/edit/leakageTest";
import EditWetTestCertification from "../pages/tests/edit/wetTestCertification";
import EditVisualInspection from "../pages/tests/edit/visualInspection";
import EditExternalVisualInspection from "../pages/tests/edit/externalVisualInspection";
import EditKMTankTrailerInspection from "../pages/tests/edit/kmTankTrailerInspection";
import EditCitgoInspection from "../pages/tests/edit/citgoInspection";
import DailyTimesheetReport from "../pages/reports/operational/dailyTimesheetReport";
import EmployeeTimesheets from "../pages/reports/operational/employeeTimesheets";
import WeeklyTimesheetReport from "../pages/reports/operational/weeklyTimesheetReport";
import AdminView from "../pages/admin";
import NotFound from "../pages/notFound";
import BuckeyeTrailerInspection from "../pages/tests/buckeyeTrailerInspection";
import OpenBuckeyeTrailerInspection from "../pages/tests/view/buckeyeTrailerInspection";
import EditBuckeyeTrailerInspection from "../pages/tests/edit/buckeyeTrailerInspection";
import MagellanTrailerCertification from "../pages/tests/magellanTrailerCertification";
import OpenMagellanTrailerCertification from "../pages/tests/view/magellanTrailerCertification";
import EditMagellanTrailerCertification from "../pages/tests/edit/magellanTrailerCertification";
import TimeOffOverview from "../pages/admin/timeOff";
import NewTimeOffEntry from "../pages/admin/timeOff/newTimeOffEntry";
import OpenTimeOff from "../pages/admin/timeOff/openTimeOff";
import ChartOfAccounts from "../pages/admin/chartOfAccounts";
import Employees from "../pages/admin/employees/index";
import CreateANewEmployee from "../pages/admin/employees/newEmployee";
import OpenEmployee from "../pages/admin/employees/employeeId";
import LaborRates from "../pages/admin/laborRates";
import TimesheetReview from "../pages/admin/timesheets";
import OpenTimesheet from "../pages/admin/timesheets/openTimesheet";
import JobWarranty from "../pages/jobs/warranty";
import CannedFees from "../pages/admin/cannedFees";
import JobPayments from "../pages/jobs/payments";
import ARAgingReport from "../pages/reports/financial/arAgingReport";
import InventoryCounts from "../pages/reports/operational/inventoryCounts";
import IncompleteParts from "../pages/reports/operational/incompleteParts";
import AllJobs from "../pages/jobs/allJobs";
import AllInvoices from "../pages/invoices/allInvoices";
import AllCustomers from "../pages/customers/allCustomers";
import AllParts from "../pages/parts/allParts";
import AllChecklists from "../pages/tests/allChecklists";
import AllAPInvoices from "../pages/ap/allAPInvoices";
import AllVendors from "../pages/vendors/allVendors";
import AllQuotes from "../pages/quotes/allQuotes";
import AllPurchaseOrders from "../pages/purchaseOrders/allPurchaseOrders";
import AllEquipment from "../pages/equipment/allEquipment";
import SalesTaxDepositReport from "../pages/reports/financial/salesTaxDeposit";
import AnnualServiceReports from "../pages/reports/customer/annialServiceReports";

const CoreLayout = ({ authState, authDispatch }) => {
  return authState.isAuth ? (
    <Routes>
      <Route path="*" element={<NavigationWrapper authState={authState} authDispatch={authDispatch} />}>
        <Route index element={<Dashboard authState={authState} authDispatch={authDispatch} />} />
        <Route path="customers" element={<AllCustomers authState={authState} authDispatch={authDispatch} />} />
        <Route path="customers/new" element={<NewCustomer authState={authState} authDispatch={authDispatch} />} />
        <Route path="customers/:id" element={<CustomerID authState={authState} authDispatch={authDispatch} />} />
        {authState.user.functionCategory !== "technician" && (
          <Route path="customers/:id/defaults" element={<CustomerDefaults authState={authState} authDispatch={authDispatch} />} />
        )}
        <Route path="customers/:id/contact" element={<CustomerContact authState={authState} authDispatch={authDispatch} />} />
        <Route path="customers/:id/ar" element={<CustomerAR authState={authState} authDispatch={authDispatch} />} />
        <Route path="customers/:id/equipment" element={<CustomerEquipment authState={authState} authDispatch={authDispatch} />} />
        <Route path="customers/:id/quotes" element={<CustomerQuotes authState={authState} authDispatch={authDispatch} />} />
        <Route path="customers/:id/jobs" element={<CustomerJobs authState={authState} authDispatch={authDispatch} />} />
        <Route path="customers/:id/invoices" element={<CustomerInvoices authState={authState} authDispatch={authDispatch} />} />
        <Route path="quotes" element={<AllQuotes authState={authState} authDispatch={authDispatch} />} />
        <Route path="quotes/new" element={<NewQuote authState={authState} authDispatch={authDispatch} />} />
        <Route path="quotes/:quoteId" element={<OpenQuote authState={authState} authDispatch={authDispatch} />} />
        {authState.user.functionCategory !== "technician" && (
          <Route path="quotes/:quoteId/review" element={<QuoteReview authState={authState} authDispatch={authDispatch} />} />
        )}
        <Route path="quotes/:quoteId/jobFromQuote" element={<JobFromQuote authState={authState} authDispatch={authDispatch} />} />
        <Route path="jobs" element={<AllJobs authState={authState} authDispatch={authDispatch} />} />
        <Route path="jobs/new" element={<NewJob authState={authState} authDispatch={authDispatch} />} />
        <Route path="jobs/:jobId" element={<OpenJob authState={authState} authDispatch={authDispatch} />} />
        <Route path="jobs/:jobId/parts" element={<JobParts authState={authState} authDispatch={authDispatch} />} />
        <Route path="jobs/:jobId/labor" element={<JobLabor authState={authState} authDispatch={authDispatch} />} />
        <Route path="jobs/:jobId/parking" element={<JobParking authState={authState} authDispatch={authDispatch} />} />
        <Route path="jobs/:jobId/loadbank" element={<JobLoadbankCharges authState={authState} authDispatch={authDispatch} />} />
        <Route path="jobs/:jobId/freight" element={<JobFreight authState={authState} authDispatch={authDispatch} />} />
        <Route path="jobs/:jobId/misc" element={<JobMisc authState={authState} authDispatch={authDispatch} />} />
        <Route path="jobs/:jobId/purchaseOrders" element={<JobPurchaseOrders authState={authState} authDispatch={authDispatch} />} />
        <Route path="jobs/:jobId/inventoryTransmittals" element={<JobInventoryTransmittals authState={authState} authDispatch={authDispatch} />} />
        <Route path="jobs/:jobId/equipment" element={<JobEquipment authState={authState} authDispatch={authDispatch} />} />
        <Route path="jobs/:jobId/tests" element={<JobTests authState={authState} authDispatch={authDispatch} />} />
        <Route path="jobs/:jobId/warranty" element={<JobWarranty authState={authState} authDispatch={authDispatch} />} />
        <Route path="jobs/:jobId/payments" element={<JobPayments authState={authState} authDispatch={authDispatch} />} />
        {authState.user.functionCategory !== "technician" && (
          <>
            <Route path="vendors" element={<AllVendors authState={authState} authDispatch={authDispatch} />} />
            <Route path="vendors/new" element={<NewVendor authState={authState} authDispatch={authDispatch} />} />
            <Route path="vendors/:vendorId" element={<OpenVendor authState={authState} authDispatch={authDispatch} />} />
          </>
        )}
        <Route path="parts" element={<AllParts authState={authState} authDispatch={authDispatch} />} />
        {authState.user.functionCategory !== "technician" && <Route path="parts/new" element={<NewPart authState={authState} authDispatch={authDispatch} />} />}
        <Route path="parts/:partId" element={<PartID authState={authState} authDispatch={authDispatch} />} />
        <Route path="invoices" element={<AllInvoices authState={authState} authDispatch={authDispatch} />} />
        <Route path="invoices/:invoiceId" element={<OpenInvoice authState={authState} authDispatch={authDispatch} />} />
        {authState.user.functionCategory !== "technician" && (
          <Route path="invoices/:invoiceId/payment" element={<CollectInvoicePayment authState={authState} authDispatch={authDispatch} />} />
        )}
        <Route path="equipment" element={<AllEquipment authState={authState} authDispatch={authDispatch} />} />
        {authState.user.functionCategory !== "technician" && (
          <Route path="equipment/new" element={<NewEquipment authState={authState} authDispatch={authDispatch} />} />
        )}
        <Route path="equipment/:equipmentId" element={<OpenEquipment authState={authState} authDispatch={authDispatch} />} />
        <Route path="equipment/:equipmentId/details" element={<EquipmentDetails authState={authState} authDispatch={authDispatch} />} />
        <Route path="equipment/:equipmentId/engine" element={<EquipmentEngine authState={authState} authDispatch={authDispatch} />} />
        <Route path="equipment/:equipmentId/ats" element={<EquipmentATS authState={authState} authDispatch={authDispatch} />} />
        <Route path="equipment/:equipmentId/ats/new" element={<NewEquipmentATS authState={authState} authDispatch={authDispatch} />} />
        <Route path="equipment/:equipmentId/ats/:atsId" element={<OpenEquipmentATS authState={authState} authDispatch={authDispatch} />} />
        <Route path="equipment/:equipmentId/contact" element={<EquipmentContact authState={authState} authDispatch={authDispatch} />} />
        <Route path="equipment/:equipmentId/notes" element={<EquipmentNotes authState={authState} authDispatch={authDispatch} />} />
        <Route path="equipment/:equipmentId/serviceSchedule" element={<EquipmentServiceSchedule authState={authState} authDispatch={authDispatch} />} />
        <Route path="equipment/:equipmentId/history" element={<EquipmentHistory authState={authState} authDispatch={authDispatch} />} />
        <Route path="equipment/:equipmentId/photos" element={<EquipmentPhotos authState={authState} authDispatch={authDispatch} />} />
        <Route path="po" element={<AllPurchaseOrders authState={authState} authDispatch={authDispatch} />} />
        <Route path="po/new" element={<CreateANewPO authState={authState} authDispatch={authDispatch} />} />
        <Route path="po/:poId" element={<OpenPO authState={authState} authDispatch={authDispatch} />} />
        {authState.user.functionCategory !== "technician" && (
          <>
            <Route path="ap" element={<AllAPInvoices authState={authState} authDispatch={authDispatch} />} />
            <Route path="ap/new" element={<NewAP authState={authState} authDispatch={authDispatch} />} />
            <Route path="ap/:apId" element={<OpenAP authState={authState} authDispatch={authDispatch} />} />
          </>
        )}
        <Route path="settings" element={<Settings authState={authState} authDispatch={authDispatch} />} />
        <Route path="settings/timesheets" element={<Timesheets authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists" element={<AllChecklists authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/annualMaintenance" element={<AnnualChecklist authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/annualMaintenance/:id" element={<OpenAnnualMaintenance authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/annualMaintenance/:id/edit" element={<EditAnnualChecklist authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/semiAnnualMaintenance" element={<SemiAnnualChecklist authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/semiAnnualMaintenance/:id" element={<OpenSemiAnnualMaintenance authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/semiAnnualMaintenance/:id/edit" element={<EditSemiAnnualChecklist authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/quarterlyMaintenance" element={<QuarterlyChecklist authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/quarterlyMaintenance/:id" element={<OpenQuarterlyMaintenance authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/quarterlyMaintenance/:id/edit" element={<EditQuarterlyChecklist authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/monthlyMaintenance" element={<MonthlyChecklist authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/monthlyMaintenance/:id" element={<OpenMonthlyMaintenance authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/monthlyMaintenance/:id/edit" element={<EditMonthlyChecklist authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/loadbank" element={<LoadbankTest authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/loadbank/:id" element={<OpenLoadbankTestLog authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/loadbank/:id/edit" element={<EditLoadbankTest authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/annualTankTrailerInspection" element={<KMAnnualTankTrailerInspection authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/annualTankTrailerInspection/:id" element={<OpenKMTankTrailerInspection authState={authState} authDispatch={authDispatch} />} />
        <Route
          path="checklists/annualTankTrailerInspection/:id/edit"
          element={<EditKMTankTrailerInspection authState={authState} authDispatch={authDispatch} />}
        />
        <Route path="checklists/levelOneService" element={<LevelOneServiceChecklist authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/levelOneService/:id" element={<OpenLevelOneService authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/levelOneService/:id/edit" element={<EditLevelOneChecklist authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/citgo" element={<CitgoInspection authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/citgo/:id" element={<OpenCitgoInspection authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/citgo/:id/edit" element={<EditCitgoInspection authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/leakageTest" element={<LeakageTest authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/leakageTest/:id" element={<OpenLeakageTest authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/leakageTest/:id/edit" element={<EditLeakageTest authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/visualInspection" element={<VisualInspection authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/visualInspection/:id" element={<OpenVisualInspection authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/visualInspection/:id/edit" element={<EditVisualInspection authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/wetTestCertification" element={<WetTestCertification authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/wetTestCertification/:id" element={<OpenWetTestCertification authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/wetTestCertification/:id/edit" element={<EditWetTestCertification authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/externalVisualInspection" element={<ExternalVisualInspection authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/externalVisualInspection/:id" element={<OpenExternalVisualInspection authState={authState} authDispatch={authDispatch} />} />
        <Route
          path="checklists/externalVisualInspection/:id/edit"
          element={<EditExternalVisualInspection authState={authState} authDispatch={authDispatch} />}
        />
        <Route path="checklists/buckeye" element={<BuckeyeTrailerInspection authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/buckeye/:id" element={<OpenBuckeyeTrailerInspection authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/buckeye/:id/edit" element={<EditBuckeyeTrailerInspection authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/magellan" element={<MagellanTrailerCertification authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/magellan/:id" element={<OpenMagellanTrailerCertification authState={authState} authDispatch={authDispatch} />} />
        <Route path="checklists/magellan/:id/edit" element={<EditMagellanTrailerCertification authState={authState} authDispatch={authDispatch} />} />
        {authState.user.functionCategory !== "technician" && (
          <>
            <Route path="reports" element={<Reports authState={authState} authDispatch={authDispatch} />} />
            <Route path="reports/apOpenInvoices" element={<APOpenInvoices authState={authState} authDispatch={authDispatch} />} />
            <Route path="reports/arAgingReport" element={<ARAgingReport authState={authState} authDispatch={authDispatch} />} />
            <Route path="reports/salesTaxDeposit" element={<SalesTaxDepositReport authState={authState} authDispatch={authDispatch} />} />
            <Route path="reports/equipmentLog" element={<EquipmentLog authState={authState} authDispatch={authDispatch} />} />
            <Route path="reports/inventoryCount" element={<InventoryCount authState={authState} authDispatch={authDispatch} />} />
            <Route path="reports/openJobReport" element={<OpenJobList authState={authState} authDispatch={authDispatch} />} />
            <Route path="reports/upcomingService" element={<UpcomingServiceReport authState={authState} authDispatch={authDispatch} />} />
            <Route path="reports/annualService" element={<AnnualServiceReports authState={authState} authDispatch={authDispatch} />} />
            <Route path="reports/dailyTimesheetReport" element={<DailyTimesheetReport authState={authState} authDispatch={authDispatch} />} />
            <Route path="reports/weeklyTimesheetReport" element={<WeeklyTimesheetReport authState={authState} authDispatch={authDispatch} />} />
            <Route path="reports/employeeTimesheets" element={<EmployeeTimesheets authState={authState} authDispatch={authDispatch} />} />
            <Route path="reports/inventoryCounts" element={<InventoryCounts authState={authState} authDispatch={authDispatch} />} />
            <Route path="reports/incompleteParts" element={<IncompleteParts authState={authState} authDispatch={authDispatch} />} />
            <Route path="admin" element={<AdminView authState={authState} authDispatch={authDispatch} />} />
            <Route path="admin/timeOff" element={<TimeOffOverview authState={authState} authDispatch={authDispatch} />} />
            <Route path="admin/timeOff/new" element={<NewTimeOffEntry authState={authState} authDispatch={authDispatch} />} />
            <Route path="admin/timeOff/:id" element={<OpenTimeOff authState={authState} authDispatch={authDispatch} />} />
            <Route path="admin/employees" element={<Employees authState={authState} authDispatch={authDispatch} />} />
            <Route path="admin/employees/new" element={<CreateANewEmployee authState={authState} authDispatch={authDispatch} />} />
            <Route path="admin/employees/:employeeId" element={<OpenEmployee authState={authState} authDispatch={authDispatch} />} />
            <Route path="admin/chartOfAccounts" element={<ChartOfAccounts authState={authState} authDispatch={authDispatch} />} />
            <Route path="admin/laborRates" element={<LaborRates authState={authState} authDispatch={authDispatch} />} />
            <Route path="admin/timesheetReview" element={<TimesheetReview authState={authState} authDispatch={authDispatch} />} />
            <Route path="admin/timesheetReview/:timesheetId" element={<OpenTimesheet authState={authState} authDispatch={authDispatch} />} />
            <Route path="admin/cannedFees" element={<CannedFees authState={authState} authDispatch={authDispatch} />} />
          </>
        )}
        <Route path="docs" element={<Documentation authState={authState} authDispatch={authDispatch} />} />
        {authState.user.functionCategory !== "technician" && (
          <Route path="changelog" element={<Changelog authState={authState} authDispatch={authDispatch} />} />
        )}
        <Route path="logout" element={<Logout authState={authState} authDispatch={authDispatch} />} />
        <Route path="*" element={<NotFound authState={authState} authDispatch={authDispatch} />} />
      </Route>
    </Routes>
  ) : (
    <Navigate to={{ pathname: "/login" }} />
  );
};

export default CoreLayout;
