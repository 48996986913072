import { useEffect, useState, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { Dialog, Transition, Combobox } from "@headlessui/react";
import { Check, ArrowSeparateVertical, RefreshDouble } from "iconoir-react";
import { v4 as uuidv4 } from "uuid";
import { formatCurrency } from "../../components/tools";
import _ from "lodash";
import { GetOneInvoice } from "../../actions/invoices";
import {
  GetTerminals,
  SetCardLocation,
  RetryCapture,
  AbortTransaction,
  SaleStatus,
  CheckCardSale,
  StartSale,
  CreateManualIntent,
} from "../../actions/payments";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Helmet } from "react-helmet-async";
import { InputNumber, Modal } from "antd";

var stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const CollectInvoicePayment = ({ authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [invoice, setInvoice] = useState({});
  const [terminals, setTerminals] = useState([]);
  const [terminal, setTerminal] = useState(null);
  const [query, setQuery] = useState("");
  const [terminalLoad, setTerminalLoad] = useState(false);
  const [terminalSale, setTerminalSale] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [retryTimeout, setRetryTimeout] = useState(true);
  const [saleId, setSaleId] = useState(null);
  const [paymentError, setPaymentError] = useState("");
  const [intent, setIntent] = useState(null);
  const [customAmount, setCustomAmount] = useState(invoice.total);
  const [partialModal, setPartialModal] = useState(false);

  const { invoiceId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneInvoice(invoiceId)
        .then((res) => {
          setInvoice(res.data.invoice);
          GetTerminals()
            .then((res) => {
              setTerminals(res.data);
              setTimeout(() => setLoading(false), 700);
            })
            .catch((err) => {
              toast.error(err.response.data ? err.response.data.message : "Error loading terminals");
              setTimeout(() => {
                setLoading(false);
              }, 700);
            });
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "Error loading invoice");
          setTimeout(() => {
            navigate("/invoices");
          }, 3000);
        });
    }
    return () => {
      inView = false;
    };
  }, [invoiceId]);

  const calculateProcessingFee = () => {
    let total = 0;
    total += calculateSubtotal();
    total += invoice.tax;
    total = parseFloat((total * 0.03).toFixed(2));
    return total;
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const filteredTerminals =
    query === ""
      ? terminals
      : terminals.filter((pos) => {
          return pos.label.toLowerCase().includes(query.toLowerCase());
        });

  const reloadTerminals = () => {
    setTerminalLoad(true);
    GetTerminals()
      .then((res) => {
        setTerminals(res.data);
        setQuery("");
        if (terminal) {
          let newTerminal = res.data.find((pos) => pos.id === terminal.id);
          if (newTerminal) {
            setTerminal(newTerminal);
          } else {
            setTerminal(null);
          }
        }
        setTimeout(() => {
          setTerminalLoad(false);
        }, 700);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setTimeout(() => {
          setTerminalLoad(false);
        }, 700);
      });
  };

  const setCardLocation = (location) => {
    setProcessing(true);
    SetCardLocation({ saleId: saleId, location })
      .then((res) => {
        toast.success("Card location set successfully");
        setTimeout(() => navigate(`/invoices/${invoiceId}`), 2500);
      })
      .catch((err) => {
        setProcessing(false);
        toast.error(err.response.data.message);
      });
  };

  const retryCapture = () => {
    setProcessing(true);
    RetryCapture({ saleId, terminal: terminal.id })
      .then((res) => {
        setSaleId(res.data.sale);
        setStep(1);
        setRetryTimeout(true);
        setPaymentError("");
        setTimeout(() => {
          setRetryTimeout(false);
        }, 15000);
        setTimeout(() => startStatusPing(res.data.sale), 2000);
        setProcessing(false);
      })
      .catch((err) => {
        setStep(3);
        setProcessing(false);
        toast.error(err.response.data.message);
      });
  };

  const cancelSale = () => {
    if (terminal && terminal.id && saleId) {
      setProcessing(true);
      AbortTransaction({ terminal: terminal.id, saleId })
        .then((res) => {
          setProcessing(false);
          toast.success("Sale cancelled successfully");
          closeModal();
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setProcessing(false);
          closeModal();
        });
    } else {
      closeModal();
    }
  };

  const startStatusPing = (sale) => {
    SaleStatus({ saleId: sale })
      .then((res) => {
        if (res.data.status === "processing" || res.data.status === "pending" || res.data.status === "requires_capture") {
          setTimeout(() => {
            setPaymentError("");
            startStatusPing(sale);
          }, 2000);
        } else if (res.data.status === "succeeded") {
          setStep(2);
          setProcessing(false);
          setTimeout(() => navigate(`/invoices/${invoiceId}`), 3000);
        } else if (res.data.status === "requires_payment_method") {
          if (res.data.paymentIntent.last_payment_error) {
            toast.error(res.data.paymentIntent.last_payment_error.message);
            setPaymentError(res.data.paymentIntent.last_payment_error.message);
            setStep(3);
            setProcessing(false);
          } else {
            setTimeout(() => startStatusPing(sale), 2000);
          }
        } else {
          setStep(3);
          setProcessing(false);
        }
      })
      .catch((err) => {
        setTimeout(() => startStatusPing(sale), 2000);
      });
  };

  const cardNextStep = (hasLocation) => {
    setTimeout(() => navigate(`/invoices/${invoiceId}`), 1500);
  };

  const renderStep = () => {
    if (step === 0) {
      return (
        <div className="flex flex-col items-start justify-start w-full gap-2">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
            Terminal Sale
          </Dialog.Title>
          <div className="flex flex-col items-start justify-start w-full gap-2 mt-2">
            <div className="w-full mt-4">
              <Combobox as="div" value={terminal} onChange={setTerminal} placeholder="Pick a terminal from the dropdown">
                <Combobox.Label className="flex flex-row justify-between w-full px-1 text-xs text-left text-gray-500">
                  <p>Terminal to use:</p>
                  <div
                    className="flex flex-row items-center justify-end gap-1 uppercase duration-150 cursor-pointer hover:text-gray-800"
                    onClick={() => reloadTerminals()}
                  >
                    <p>Reload Terminals</p>
                    <RefreshDouble className="w-3 h-3" />
                  </div>
                </Combobox.Label>
                <div className="relative mt-1">
                  <Combobox.Input
                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(pos) => pos?.label}
                    placeholder="Pick a terminal from the dropdown"
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
                    <ArrowSeparateVertical className="w-5 h-5 text-gray-400" aria-hidden="true" />
                  </Combobox.Button>

                  {filteredTerminals.length > 0 && !terminalLoad && (
                    <Combobox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {filteredTerminals.map((pos) => (
                        <Combobox.Option
                          key={pos.id}
                          value={pos}
                          className={({ active }) =>
                            classNames("relative cursor-default select-none py-2 pl-3 pr-9", active ? "bg-gray-200 text-black" : "text-gray-900")
                          }
                        >
                          {({ active, selected }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    "inline-block h-2 w-2 flex-shrink-0 rounded-full",
                                    pos.status === "online" ? "bg-green-400" : "bg-gray-200",
                                  )}
                                  aria-hidden="true"
                                />
                                <span className={classNames("ml-3 truncate", selected && "font-semibold")}>
                                  {pos.label}
                                  <span className="sr-only"> is {pos.status === "online" ? "online" : "offline"}</span>
                                </span>
                              </div>

                              {selected && (
                                <span className={classNames("absolute inset-y-0 right-0 flex items-center pr-4", active ? "text-black" : "text-gray-600")}>
                                  <Check className="w-5 h-5" aria-hidden="true" />
                                </span>
                              )}
                            </>
                          )}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  )}
                </div>
              </Combobox>
            </div>
            <div className="flex flex-row items-center justify-end w-full gap-2 pt-3 mt-5 border-t border-gray-300">
              <SecondaryButton label="Cancel" callback={() => closeModal()} />
              <PrimaryButton label="Next Step" callback={() => nextStep()} />
            </div>
          </div>
        </div>
      );
    } else if (step === 1) {
      return (
        <div className="w-full">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-center text-gray-900">
            Process a New Sale
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-center text-gray-500">Please instruct the customer to complete the sale on the terminal.</p>
            <p className="text-sm text-center text-gray-500">Once the customer is done, capture the payment by clicking on the button below.</p>
          </div>
          <div className="flex flex-row items-center justify-center gap-2 mt-5 sm:mt-6">
            <SecondaryButton callback={() => closeModal()} disabled={processing} label="CANCEL" />
            <SecondaryButton callback={() => retryCapture()} disabled={retryTimeout} label="Retry" />
          </div>
        </div>
      );
    } else if (step === 2) {
      return (
        <div className="w-full">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-center text-gray-900">
            Process a New Sale
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-center text-gray-500">Sale successfully captured!</p>
            <p className="text-sm text-center text-gray-500">A receipt is being printed.</p>
          </div>
        </div>
      );
    } else if (step === 3) {
      return (
        <div className="w-full">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-center text-gray-900">
            Capture Failed
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-center text-gray-500">
              There was an error capturing the payment. Press the button below to try again or cancel the sale.
            </p>
          </div>
          <div className="mt-2">
            <p className="text-sm text-center text-gray-500">{paymentError}</p>
          </div>
          <div className="flex flex-row items-center justify-between gap-2 mt-5 sm:mt-6">
            <SecondaryButton callback={() => cancelSale()} disabled={processing} label="CANCEL SALE" />
            <SecondaryButton callback={() => retryCapture()} disabled={processing} label="RETRY" />
          </div>
        </div>
      );
    } else if (step === 4) {
      const stripeOptions = {
        clientSecret: intent.client_secret,
        appearance: {
          theme: "stripe",
        },
      };
      return (
        <div className="w-full">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-center text-gray-900">
            Enter Card Details
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-center text-gray-500">Please enter the card details below.</p>
          </div>
          {!processing && (
            <Elements stripe={stripePromise} options={stripeOptions}>
              <StripeForm intent={intent} saleId={saleId} cancelSale={cancelSale} nextStep={cardNextStep} />
            </Elements>
          )}
        </div>
      );
    } else if (step === 5) {
      return (
        <div className="w-full">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-center text-gray-900">
            Sale Details
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-center text-gray-500">Where was this sale processed?</p>
          </div>
          <div className="flex flex-row items-center justify-center w-2/3 gap-2 py-2 mx-auto mt-3">
            <SecondaryButton callback={() => setCardLocation("augusta")} disabled={processing} label={processing ? "PROCESSING" : "Augusta, GA"} />
            <SecondaryButton callback={() => setCardLocation("northAugusta")} disabled={processing} label={processing ? "PROCESSING" : "North Augusta, GA"} />
          </div>
        </div>
      );
    } else if (step === 6) {
      return (
        <div className="w-full">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-center text-gray-900">
            Sale Complete
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-center text-gray-500">Manual credit card sale processed successfully. You can now close this popup.</p>
          </div>
          <div className="flex flex-row items-center justify-center w-full mt-10">
            <SecondaryButton callback={() => closeModal()} label="Close" />
          </div>
        </div>
      );
    }
  };

  const nextStep = () => {
    if (step === 0) {
      if (!terminal || !terminal.id) {
        toast.error("Please select a terminal");
        reloadTerminals();
        return;
      }
      if (terminal.status !== "online") {
        toast.error("Terminal not online, please select another terminal or resolve the issue.");
        reloadTerminals();
        return;
      }
      setProcessing(true);
      let partsTotal = 0;
      let gogTotal = 0;
      let servicesTotal = 0;
      invoice.parts.forEach((part) => {
        if (part.category === "gog") {
          gogTotal += part.extPrice;
        } else {
          partsTotal += part.extPrice;
        }
      });
      invoice.misc.forEach((misc) => {
        if (misc.miscType === "part") {
          partsTotal += misc.chargeOutPrice;
        } else {
          servicesTotal += misc.chargeOutPrice;
        }
      });
      let toSend = {
        terminal: terminal.id,
        labor: invoice.laborTotal,
        reference: invoiceId,
        tax: invoice.tax,
        category: "invoice",
        includeTax: invoice.taxStatus === "taxable" ? true : false,
        parts: partsTotal,
        gog: gogTotal,
        misc: invoice.parkingTotal + invoice.loadbankTotal,
        shopFee: invoice.shopFeeTotal,
        freight: invoice.freightTotal,
        services: servicesTotal,
        processingFees: calculateProcessingFee(),
        total: calculateBalanceDue(),
        subtotal: invoice.subtotal,
      };
      toSend.processingFees = parseFloat(((toSend.total / 1.03) * 0.03).toFixed(2));
      StartSale(toSend)
        .then((res) => {
          closePartialModal();
          setSaleId(res.data.sale);
          setStep(1);
          setRetryTimeout(true);
          setTimeout(() => {
            setRetryTimeout(false);
          }, 15000);
          setTimeout(() => startStatusPing(res.data.sale), 10000);
          setProcessing(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  const renderTerminalSale = () => {
    return (
      <Transition.Root show={terminalSale} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                  <div className="flex flex-col items-start justify-start w-full gap-2 mt-3">{renderStep()}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const closeModal = () => {
    setTerminalSale(false);
    setQuery("");
    setTerminal(null);
  };

  const submitCardSale = () => {
    setProcessing(true);
    let partsTotal = 0;
    let gogTotal = 0;
    let servicesTotal = 0;
    invoice.parts.forEach((part) => {
      if (part.category === "gog") {
        gogTotal += part.extPrice;
      } else {
        partsTotal += part.extPrice;
      }
    });
    invoice.misc.forEach((misc) => {
      if (misc.miscType === "part") {
        partsTotal += misc.chargeOutPrice;
      } else {
        servicesTotal += misc.chargeOutPrice;
      }
    });
    let toSend = {
      labor: invoice.laborTotal,
      reference: invoiceId,
      tax: invoice.tax,
      includeTax: invoice.taxStatus === "taxable" ? true : false,
      parts: partsTotal,
      gog: gogTotal,
      misc: invoice.parkingTotal + invoice.loadbankTotal,
      shopFee: invoice.shopFeeTotal,
      freight: invoice.freightTotal,
      services: servicesTotal,
      processingFees: calculateProcessingFee(),
      total: calculateBalanceDue(),
      subtotal: invoice.subtotal,
      category: "invoice",
    };
    toSend.processingFees = parseFloat(((toSend.total / 1.03) * 0.03).toFixed(2));
    CreateManualIntent(toSend)
      .then((res) => {
        closePartialModal();
        setSaleId(res.data.saleId);
        setIntent(res.data.intent);
        setStep(4);
        setProcessing(false);
        setTimeout(() => {
          setTerminalSale(true);
        }, 700);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setProcessing(false);
      });
  };

  const calculatePayments = () => {
    let paid = 0;
    if (invoice.payments) {
      for (let i = 0; i < invoice.payments.length; i++) {
        paid += parseFloat(invoice.payments[i].paymentAmount);
      }
    }
    let paidFees = calculatePaidCardFees();
    paid = parseFloat((paid - paidFees).toFixed(2));
    return paid;
  };

  const calculatePaymentsMade = () => {
    let paid = 0;
    if (invoice.payments) {
      for (let i = 0; i < invoice.payments.length; i++) {
        paid += parseFloat(invoice.payments[i].paymentAmount);
      }
    }
    return paid;
  };

  const calculatePaidCardFees = () => {
    let paidWithCard = invoice.payments.filter((p) => p.paymentMethod === "card");
    let total = 0;
    for (let i = 0; i < paidWithCard.length; i++) {
      total += parseFloat(((paidWithCard[i].paymentAmount / 1.03) * 0.03).toFixed(2));
    }
    if (invoiceId === "585271b0-6614-4f48-8bc8-0eb70b7afca0") {
      total += 0.01;
    }
    return total;
  };

  const calculateLabor = () => {
    let total = 0;
    invoice.labor.forEach((labor) => {
      total += parseFloat(labor.quotedPrice);
    });
    return total;
  };

  const calculateParts = () => {
    let total = 0;
    invoice.parts.forEach((part) => {
      if (part.category === "parts") {
        total += parseFloat(part.extPrice);
      }
    });
    invoice.misc.forEach((part) => {
      if (part.miscType === "part") {
        total += parseFloat(part.chargeOutPrice);
      }
    });
    return total;
  };

  const calculateGOG = () => {
    let total = 0;
    invoice.parts.forEach((part) => {
      if (part.category !== "parts") {
        total += parseFloat(part.extPrice);
      }
    });
    return total;
  };

  const calculateFreight = () => {
    let total = 0;
    invoice.freight.forEach((freight) => {
      total += parseFloat(freight.freightChargeOut);
    });
    return total;
  };

  const calculateMisc = () => {
    let total = 0;
    invoice.loadbank.forEach((el) => {
      total += parseFloat(el.unitCharge);
    });
    invoice.parking.forEach((el) => {
      total += parseFloat(el.totalCharge);
    });
    return total;
  };

  const calculateServices = () => {
    let total = 0;
    invoice.misc.forEach((misc) => {
      if (misc.miscType === "service") total += parseFloat(misc.chargeOutPrice);
    });
    return total;
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    subtotal += calculateLabor();
    subtotal += calculateParts();
    subtotal += calculateGOG();
    subtotal += calculateFreight();
    subtotal += calculateServices();
    subtotal += calculateMisc();
    subtotal += invoice.shopFeeTotal;
    return parseFloat(subtotal.toFixed(2));
  };

  const calculateTotal = () => {
    let total = 0;
    total += calculateSubtotal();
    total += invoice.tax;
    total = total - calculatePaymentsMade();
    return total;
  };

  const calculateTotalVisible = () => {
    let total = 0;
    total += calculateSubtotal();
    total += invoice.tax;
    total = parseFloat((total * 1.03).toFixed(2));
    return total;
  };

  const calculateBalanceDue = () => {
    let val = 0;
    val = parseFloat((val + invoice.subtotal).toFixed(2));
    val = parseFloat((val + invoice.tax).toFixed(2));
    // val = parseFloat(val.toFixed(2));
    val = parseFloat((val * 1.03).toFixed(2));
    val = val - calculatePaymentsMade();
    val = parseFloat(val.toFixed(2));
    if (invoice.invoiceId === "e4aa2c5f-14d7-484d-8730-e39709a900c2") {
      val += 0.01;
    }
    if (val < 0.1 && val > -0.1) {
      return 0;
    } else {
      return val;
    }
  };

  const calculateCardFees = () => {
    // let paidWithCard = invoice.payments.filter((p) => p.paymentMethod === "card");
    let total = 0;
    // for (let i = 0; i < paidWithCard.length; i++) {
    //   total += parseFloat(((paidWithCard[i].paymentAmount / 1.03) * 0.03).toFixed(2));
    // }
    if (invoiceId === "585271b0-6614-4f48-8bc8-0eb70b7afca0") {
      total += 0.01;
    }
    return total;
  };

  const triggerPartialPayment = () => {
    let amt = invoice.total - calculatePayments();
    setCustomAmount(amt);
    setPartialModal(true);
  };

  const closePartialModal = () => {
    setPartialModal(false);
    setCustomAmount(invoice.total);
  };

  const renderPartialPaymentModal = () => {
    const limit = invoice.total - calculatePayments();

    return (
      <Modal open={partialModal} onCancel={() => closePartialModal()} title="Collect Partial Payment" centered destroyOnClose width={600} footer={[]}>
        <div className="flex flex-row items-center justify-between w-full gap-5 py-4">
          <p className="text-base font-medium">Enter the amount you with to collect:</p>
          <InputNumber
            defaultValue={customAmount}
            max={limit}
            min={0}
            step={0.01}
            precision={2}
            prefix="$"
            className="text-right w-28"
            controls={false}
            onChange={(e) => setCustomAmount(e)}
          />
        </div>
        <div className="flex items-center justify-between w-full mt-5">
          <SecondaryButton label="Cancel" />
          <div className="flex items-center justify-end gap-2">
            <PrimaryButton label="Send to Terminal" />
            <PrimaryButton label="Manually enter" />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>Collect Payment - {invoice.invoiceNo.replace("INV ", "").replace("HTJ-", "")} | HTPS ERP</title>
          </Helmet>
          <div className="flex flex-row items-center justify-between w-full pb-3 text-sm border-b border-gray-300">
            <div className="flex flex-col items-start justify-center gap-2">
              <p className="text-base font-semibold uppercase">Collect Payment for invoice {invoice.invoiceNo}</p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full gap-5 mt-5">
            <div className="flex flex-col items-start justify-start w-2/5 gap-2 mx-auto">
              <table className="min-w-full">
                <tbody>
                  <Fragment key="Line Items">
                    <tr className="border-t border-gray-500">
                      <th colSpan={5} scope="colgroup" className="px-2 py-2 text-sm font-semibold text-left text-gray-900 bg-gray-50">
                        Line Items
                      </th>
                    </tr>
                    <tr key={uuidv4()} className="border-t border-gray-500">
                      <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Labor:</td>
                      <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">{formatCurrency(invoice.laborTotal)}</td>
                    </tr>
                    <tr key={uuidv4()} className="border-t border-gray-300">
                      <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Parts:</td>
                      <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">{formatCurrency(invoice.partsTotal)}</td>
                    </tr>
                    <tr key={uuidv4()} className="border-t border-gray-300">
                      <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Gas, Oil, Grease:</td>
                      <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">{formatCurrency(invoice.gogTotal)}</td>
                    </tr>
                    <tr key={uuidv4()} className="border-t border-gray-300">
                      <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Freight:</td>
                      <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">{formatCurrency(invoice.freightTotal)}</td>
                    </tr>
                    <tr key={uuidv4()} className="border-t border-gray-300">
                      <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Services:</td>
                      <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">
                        {formatCurrency(invoice.servicesTotal)}
                      </td>
                    </tr>
                    <tr key={uuidv4()} className="border-t border-gray-300">
                      <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Misc. Charges:</td>
                      <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">
                        {formatCurrency(invoice.parkingTotal + invoice.loadbankTotal)}
                      </td>
                    </tr>
                    <tr key={uuidv4()} className="border-t border-gray-300">
                      <td className="flex flex-row items-center justify-start w-3/5 gap-2 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">
                        <p>Shop Fees:</p>
                      </td>
                      <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">{formatCurrency(invoice.shopFeeTotal)}</td>
                    </tr>
                  </Fragment>
                  <Fragment key="Totals">
                    <tr className="border-t border-gray-500">
                      <th colSpan={5} scope="colgroup" className="px-2 py-2 text-sm font-semibold text-left text-gray-900 bg-gray-50">
                        Totals
                      </th>
                    </tr>
                    <tr key={uuidv4()} className="border-t border-gray-500">
                      <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Subtotal:</td>
                      <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">{formatCurrency(invoice.subtotal)}</td>
                    </tr>
                    <tr key={uuidv4()} className="border-t border-gray-300">
                      <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Tax:</td>
                      <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">{formatCurrency(invoice.tax)}</td>
                    </tr>
                    <tr key={uuidv4()} className="border-t border-gray-300">
                      <td className="w-3/5 px-2 py-2 text-sm font-medium text-gray-500 whitespace-nowrap">Processing Fees:</td>
                      <td className="w-2/5 px-2 py-2 text-sm font-medium text-right text-gray-500 whitespace-nowrap">
                        {formatCurrency(calculateProcessingFee())}
                      </td>
                    </tr>
                    <tr key={uuidv4()} className="border-t border-gray-700">
                      <td className="w-3/5 px-2 py-2 text-sm font-semibold text-gray-900 whitespace-nowrap">TOTAL:</td>
                      <td className="w-2/5 px-2 py-2 text-sm font-semibold text-right text-gray-900 whitespace-nowrap">
                        {formatCurrency(calculateTotalVisible())}
                      </td>
                    </tr>
                    {calculatePayments() > 0 && (
                      <tr key={uuidv4()} className="border-t border-gray-700">
                        <td className="w-3/5 px-2 py-2 text-sm font-semibold text-gray-900 whitespace-nowrap">PAYMENTS MADE:</td>
                        <td className="w-2/5 px-2 py-2 text-sm font-semibold text-right text-gray-900 whitespace-nowrap">
                          {formatCurrency(calculatePaymentsMade())}
                        </td>
                      </tr>
                    )}
                    {calculatePayments() > 0 && (
                      <tr key={uuidv4()} className="border-t border-gray-700">
                        <td className="w-3/5 px-2 py-2 text-sm font-semibold text-gray-900 whitespace-nowrap">BALANCE DUE:</td>
                        <td className="w-2/5 px-2 py-2 text-sm font-semibold text-right text-gray-900 whitespace-nowrap">
                          {formatCurrency(calculateBalanceDue())}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                </tbody>
              </table>
            </div>
            <div className="flex flex-row items-start justify-center w-3/5 gap-2">
              <SecondaryButton label="Go Back" callback={() => navigate(`/invoices/${invoiceId}`)} />
              <SecondaryButton label="Transfer to Terminal" callback={() => setTerminalSale(true)} />
              <SecondaryButton label="Enter Card Manually" callback={() => submitCardSale()} />
              {/* <SecondaryButton label="Collect Partial Amount" callback={() => triggerPartialPayment()} /> */}
            </div>
          </div>
          {renderTerminalSale()}
          {renderPartialPaymentModal()}
        </>
      )}
    </div>
  );
};

const StripeForm = ({ intent, saleId, cancelSale, nextStep }) => {
  const [processing, setProcessing] = useState(false);
  const [nameOnCard, setNameOnCard] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const saleCancel = () => {
    cancelSale();
  };

  const submitCard = async () => {
    if (!stripe || !elements) {
      toast.error("Stripe not initialized");
      return;
    }
    if (nameOnCard === "") {
      toast.error("Please enter the name on the card");
      return;
    }
    let cardElement = elements.getElement(CardElement);
    setProcessing(true);
    stripe
      .confirmCardPayment(intent.client_secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: nameOnCard,
          },
        },
      })
      .then(function (result) {
        if (result.error) {
          setProcessing(false);
          toast.error(result.error.message);
        } else {
          let piId = result.paymentIntent.id;
          CheckCardSale({ saleId: saleId, paymentIntent: piId })
            .then((res) => {
              toast.success("Payment Successful");
              nextStep(res.data.location);
            })
            .catch((err) => {
              toast.error(err.response.data.message);
              setProcessing(false);
            });
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setProcessing(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full">
      <div className="w-2/3 mx-auto my-2">
        <label htmlFor="nameOnCard" className="block w-full ml-1 text-xs text-gray-500">
          Name on Card
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="nameOnCard"
            id="nameOnCard"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
            placeholder="John Doe"
            aria-describedby="Name on Card"
            onChange={(e) => setNameOnCard(e.target.value)}
          />
        </div>
      </div>
      <div className="w-2/3 mx-auto mt-2">
        <label htmlFor="CardElement" className="block w-full ml-1 text-xs text-gray-500">
          Card Information
        </label>
      </div>
      <div className="w-2/3 p-2 mx-auto mt-1 border border-gray-300 rounded-md">
        <CardElement
          className="card"
          options={{
            showIcon: true,
            style: {
              base: {
                backgroundColor: "white",
              },
              paddingTop: "5px",
            },
          }}
        />
      </div>
      <div className="flex flex-row items-center justify-between w-2/3 gap-2 mx-auto mt-5 sm:mt-6">
        <SecondaryButton callback={() => saleCancel()} disabled={processing} label="CANCEL SALE" />
        <SecondaryButton callback={() => submitCard()} disabled={processing} label={processing ? "PROCESSING" : "SUBMIT"} />
      </div>
    </div>
  );
};

export default CollectInvoicePayment;
