import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import { OpenInWindow, Printer } from "iconoir-react";
import { GetAnnualServiceReport } from "../../../actions/reports";
import dayjs from "dayjs";
import { GenerateEquipmentAnnualServicesReport } from "../../../data/pdf";
import { Helmet } from "react-helmet-async";
import { Select } from "antd";

const AnnualServiceReports = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;

    if (inView) {
      const currentMonth = dayjs().startOf("month").format("MM-DD-YYYY");
      GetAnnualServiceReport(currentMonth)
        .then((res) => {
          setServices(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "We encountered an error while loading Equipment Service data");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const printReport = () => {
    let capitalize = generateMonthDates()[0].label;
    console.log(capitalize);
    let data = services;
    if (data.length > 0) {
      let doc = GenerateEquipmentAnnualServicesReport(data, capitalize);
      doc.setProperties({
        title: `Hi-Tech Power Systems | Equipment Annual Service Report for ${capitalize}`,
        subject: `Hi-Tech Power Systems | Equipment Annual Service Report for ${capitalize}`,
        author: "Hypertek Solutions LLC",
        keywords: "",
        creator: "contact@hypertek.dev",
      });
      window.open(doc.output("bloburl"), "_blank");
    } else {
      toast.error(`No scheduled services found for the month of ${capitalize}`);
    }
  };

  const generateMonthDates = () => {
    const startMonth = dayjs("2023-07-01");
    const endMonth = dayjs();
    const months = [];

    let currentMonth = startMonth;

    while (currentMonth.isBefore(endMonth) || currentMonth.isSame(endMonth, "month")) {
      months.push({
        label: currentMonth.format("MMMM, YYYY"),
        value: currentMonth.format("MM-DD-YYYY"),
      });
      currentMonth = currentMonth.add(1, "month");
    }

    return months.reverse();
  };

  const updateSelectedPeriod = (period) => {
    setLoading(true);
    GetAnnualServiceReport(period)
      .then((res) => {
        setServices(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        setServices([]);
        toast.error(err.response.data ? err.response.data.message : "We encountered an error while loading Equipment Service data");
        setLoading(false);
      });
  };

  let eqTypes = {
    generator: "Generator",
    pressureWasher: "Pressure Washer",
    truck: "Truck",
    trailer: "Trailer",
    welder: "Welder",
    airCompressor: "Air Compressor",
    other: "Other",
  };

  const renderTableRow = (data) => {
    return (
      <tr className="even:bg-gray-50">
        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-3">{data.code}</td>
        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{dayjs(data.date).format("MM/DD/YYYY")}</td>
        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
          {data.customerCode} {data.customerName}
        </td>
        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{eqTypes[data.type]}</td>
        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{data.make}</td>
        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">{data.model}</td>
        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
          {data.serialNumber} {data.serialNumber2 && (data.serialNumber2.length ?? 0 > 0) ? data.serialNumber2 : ""}
        </td>
        <td className="px-3 py-4 text-sm font-medium whitespace-nowrap">{renderStatus(data.status)}</td>
        <td className="relative py-4 pl-3 whitespace-nowrap pr-4sm:pr-3">
          <button
            onClick={() => window.open(`/jobs/${data.elId}`, "_blank")}
            className="py-1.5 px-2 inline-flex justify-center items-center gap-2 rounded-md border-2 border-gray-200 font-semibold text-gray-500 hover:text-white hover:bg-black hover:border-black focus:outline-none focus:ring-0 transition-all text-xs uppercase"
          >
            <OpenInWindow className="w-4 h-4" />
            Open
          </button>
        </td>
      </tr>
    );
  };

  const renderStatus = (status) => {
    switch (status) {
      case "open":
        return (
          <div className="flex items-center justify-center">
            <span className="items-center justify-center block w-full px-2 py-1 text-xs font-medium text-center text-gray-900 bg-gray-500 rounded-md bg-opacity-10 ring-1 ring-inset ring-gray-800/10">
              Open
            </span>
          </div>
        );
      case "invoiced":
        return (
          <div className="flex items-center justify-center">
            <span className="items-center justify-center block w-full px-2 py-1 text-xs font-medium text-center text-green-700 rounded-md bg-green-50 ring-1 ring-inset ring-green-600/20">
              Invoiced
            </span>
          </div>
        );
      case "void":
        return (
          <div className="flex items-center justify-center">
            <span className="items-center justify-center block w-full px-2 py-1 text-xs font-medium text-center text-red-700 rounded-md bg-red-50 ring-1 ring-inset ring-red-600/10">
              VOID
            </span>
          </div>
        );
      case "complete":
        return (
          <div className="flex items-center justify-center">
            <span className="items-center justify-center block w-full px-2 py-1 text-xs font-medium text-center text-green-700 rounded-md bg-green-50 ring-1 ring-inset ring-green-600/20">
              Completed
            </span>
          </div>
        );
      default:
        return (
          <div className="flex items-center justify-center">
            <span className="items-center justify-center block w-full px-2 py-1 text-xs font-medium text-center text-gray-600 rounded-md bg-gray-50 ring-1 ring-inset ring-gray-500/10">
              Unknown
            </span>
          </div>
        );
    }
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Annual Service Report | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-start justify-start w-full h-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">Equipment Annual Service Report</p>
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-col items-start justify-center gap-1">
            <p className="text-sm uppercase text-gray-500/90">Equipment Annual Service Report</p>
            <p className="text-sm text-gray-600/80">Lists all completed annual service jobs, broken down in month-to-month reports.</p>
          </div>
          <div className="flex flex-row items-center justify-end gap-2">
            <Select
              placeholder="Pick a Month"
              options={generateMonthDates()}
              className="-mt-1 w-96"
              onChange={(v) => updateSelectedPeriod(v)}
              defaultValue={generateMonthDates()[0].value}
            />
            <button
              onClick={() => printReport()}
              className="py-1.5 px-4 flex justify-center items-center gap-2 rounded-md border-2 border-gray-200 font-semibold text-gray-500 hover:text-white hover:bg-black hover:border-black focus:outline-none focus:ring-0 transition-all text-sm whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={loading || services.length === 0}
            >
              <Printer className="w-5 h-5" />
              Print Out
            </button>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-2 py-4 border-b border-gray-300">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                  Job No.
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Open Date
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Customer
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Equip. Type
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Equip. Make
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Equip. Model
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Equip. Serial No.
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Status
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3 text-right text-sm font-semibold text-gray-900">
                  <span className="sr-only">View</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-gray-100">{!loading && services.map((service) => renderTableRow(service))}</tbody>
          </table>
          {loading && (
            <div className="flex items-center justify-center w-full py-4">
              <p className="text-sm text-gray-500">Loading...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnnualServiceReports;
