import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as _ from "lodash";
import {
  AppleMac,
  BookStack,
  Calculator,
  Calendar,
  FavouriteBook,
  FontQuestion,
  Hourglass,
  Okrs,
  Packages,
  PasteClipboard,
  PercentRotateOut,
  Settings,
  TaskList,
  Timer,
} from "iconoir-react";
import { Helmet } from "react-helmet-async";

const Reports = ({ authState, authDispatch }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <Helmet>
        <title>Reports | HTPS ERP</title>
      </Helmet>
      <div className="flex flex-col items-start justify-start w-full gap-2 px-1 mt-1">
        <div className="flex flex-row items-center justify-between w-full pb-5 mb-5 border-b border-gray-300">
          <p className="text-xl font-bold uppercase">Reports</p>
        </div>
        <p className="text-sm uppercase text-gray-500/90">Financial Reports</p>
        <div className="grid w-full grid-cols-3 gap-5">
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/reports/apOpenInvoices")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <Okrs className="w-4 h-4" />
              <p className="font-medium text-gray-600">Open AP Invoices Report</p>
            </div>
            <p className="text-sm text-gray-600/80">View and manage unpaid invoices, facilitating better vendor payment tracking and flow management.</p>
          </div>
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/reports/arAgingReport")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <FavouriteBook className="w-4 h-4" />
              <p className="font-medium text-gray-600">AR Aging Report</p>
            </div>
            <p className="text-sm text-gray-600/80">An overview of outstanding payments from customers and how long they are past due.</p>
          </div>
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/reports/salesTaxDeposit")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <PercentRotateOut className="w-4 h-4" />
              <p className="font-medium text-gray-600">Sales Tax Deposit Report</p>
            </div>
            <p className="text-sm text-gray-600/80">A detailed report of all sales tax collected, broken down by amounts, month and location.</p>
          </div>
        </div>
        <p className="mt-3 text-sm uppercase text-gray-500/90">Operational Reports</p>
        <div className="grid w-full grid-cols-3 gap-5">
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/reports/openJobReport")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <PasteClipboard className="w-4 h-4" />
              <p className="font-medium text-gray-600">Open Jobs Report</p>
            </div>
            <p className="text-sm text-gray-600/80">
              Generate a report outlining all of the currently open and in progress jobs, briefly reviewing their details and status.
            </p>
          </div>
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/reports/inventoryCount")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <TaskList className="w-4 h-4" />
              <p className="font-medium text-gray-600">Inventory Count Sheet</p>
            </div>
            <p className="text-sm text-gray-600/80">
              Accurately assess the actual state of your inventory by doing a physical count of all items in your warehouse.
            </p>
          </div>
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/reports/dailyTimesheetReport")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <Timer className="w-4 h-4" />
              <p className="font-medium text-gray-600">Daily Timesheet Report</p>
            </div>
            <p className="text-sm text-gray-600/80">Get a quick overview of all the hours worked by your employees on a daily basis.</p>
          </div>
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/reports/weeklyTimesheetReport")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <Timer className="w-4 h-4" />
              <p className="font-medium text-gray-600">Weekly Timesheet Report</p>
            </div>
            <p className="text-sm text-gray-600/80">Get a quick overview of all the hours worked by your employees on a weekly basis.</p>
          </div>
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/reports/employeeTimesheets")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <Hourglass className="w-4 h-4" />
              <p className="font-medium text-gray-600">Employee Timesheets</p>
            </div>
            <p className="text-sm text-gray-600/80">Get a timesheet report for a specific employee, detailing all of their hours worked and breaks taken.</p>
          </div>
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/reports/inventoryCounts")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <AppleMac className="w-4 h-4" />
              <p className="font-medium text-gray-600">Inventory Counts [App]</p>
            </div>
            <p className="text-sm text-gray-600/80">Generate a comprehensive report for all past inventory counts, and manage the parts with missing data.</p>
          </div>
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/reports/incompleteParts")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <FontQuestion className="w-4 h-4" />
              <p className="font-medium text-gray-600">Incomplete Parts</p>
            </div>
            <p className="text-sm text-gray-600/80">Generate a comprehensive report for all parts with incomplete data.</p>
          </div>
        </div>
        <p className="mt-3 text-sm uppercase text-gray-500/90">Customer Reports</p>
        <div className="grid w-full grid-cols-3 gap-5">
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/reports/equipmentLog")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <Packages className="w-4 h-4" />
              <p className="font-medium text-gray-600">Equipment Log by Customer</p>
            </div>
            <p className="text-sm text-gray-600/80">Historical overview of all relevant quotes, invoices, and jobs for customer's equipment.</p>
          </div>
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/reports/annualService")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <BookStack className="w-4 h-4" />
              <p className="font-medium text-gray-600">Annual Services Report</p>
            </div>
            <p className="text-sm text-gray-600/80">Lists all completed annual service jobs, broken down in month-to-month reports.</p>
          </div>
          <div
            className="flex flex-col items-start justify-start gap-2 p-5 transition-all duration-300 border border-gray-300 rounded-lg cursor-pointer hover:shadow-md"
            onClick={() => navigate("/reports/upcomingService")}
          >
            <div className="flex flex-row items-center justify-start gap-2">
              <Calendar className="w-4 h-4" />
              <p className="font-medium text-gray-600">Upcoming Equipment Services</p>
            </div>
            <p className="text-sm text-gray-600/80">Lists all equipment with upcoming scheduled maintenance due soon, broken down in month-to-month reports.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
